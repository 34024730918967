html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #eff1f1;
}

a { color: #00abc5; }
a:link { color: #00abc5; }
a:visited { color: #00abc5; }
a:focus { color: #0095ac; }
a:hover { color: #0095ac; }
a:active { color: #0095ac; }
